<template>
  <div class="dictionaryData">
    <!-- 搜索筛选 -->
    <FormSearch
      v-if="!dictType"
      :getdata="getdata"
      :form-inline="formInline"
      :form-item-arr="searchFormItemArr"
      :reset="false"
    />
    <!-- 字典类型的添加按钮 -->
    <!--列表-->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button v-if="!dictType" size="small" type="primary" @click="addAndEdit">
          添加
        </el-button>
        <el-button v-else size="small" type="primary" :loading="loading" class="dictTypeTitle" @click="addAndEdit(6)">
          添加
        </el-button>
      </div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :loading="loading"
        :operation-button="operationButton"
        :operation-width="80"
      >
        <template #status="{ row }">
          <span v-if="row.status == 0">无效</span>
          <span v-else-if="row.status == 1">有效</span>
          <span v-else-if="row.status == 2">删除</span>
        </template>
      </Table>
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <!-- 编辑与新增界面 -->
    <el-dialog :title="title" :visible.sync="editFormVisible" :append-to-body="true" width="40%" :close-on-click-modal="false">
      <el-form ref="editForm" label-width="108px" :model="editForm" :rules="rules">
        <el-form-item label="字典类型名称" prop="dictType">
          <el-select v-model="editForm.dictType" style="width:100%" size="small" :disabled="dictType?true:false" filterable placeholder="请选择字典类型名称" @change="$forceUpdate()">
            <el-option label="全部" value />
            <el-option
              v-for="item in dictionaryTypeArr"
              :key="item.dictType"
              :label="item.descrip"
              :value="item.dictType"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="字典项编码" prop="dictId">
          <el-input v-model="editForm.dictId" size="small" auto-complete="off" placeholder="请输入字典项编码" />
        </el-form-item>
        <el-form-item label="字典项描述" prop="dictName">
          <el-input v-model="editForm.dictName" size="small" auto-complete="off" placeholder="请输入字典项描述" />
        </el-form-item>
        <el-form-item label="是否有效" prop="status">
          <el-radio v-model="editForm.status" :label="0">
            无效
          </el-radio>
          <el-radio v-model="editForm.status" :label="1">
            有效
          </el-radio>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="editForm.sort" size="small" auto-complete="off" placeholder="请输入数字，数字越小越像前" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" plain class="widen-button" @click="editFormVisible = false">
          取消
        </el-button>
        <!-- <el-button size="small" @click="$resetForm('editForm')" type="success" plain>重置</el-button> -->
        <el-button size="small" type="primary" :loading="loading" class="title widen-button" @click="submitForm('editForm')">
          保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '../../components/Pagination2'
import { getDictionaryTypeAll, getDictionaryDataByPage, saveDictionaryData } from '../../api/systemManagement'
import Table from '@/components/Table'
import FormSearch from '../../components/FormSearch'
export default {
  components: { Pagination, Table, FormSearch },
  props: {
    dictType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editForm: {},
      rules: {
        dictId: [{ required: true, message: '请输入字典项编码', trigger: 'blur' }],
        dictName: [{ required: true, message: '请输入字典项描述', trigger: 'blur' }],
        sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        dictType: [{ required: true, message: '请选择字典类型', trigger: 'change' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }]
      },
      formInline: {
        pageNum: 1,
        pageSize: 10
      },
      title: '添加字典项',
      listData: [],
      loading: false,
      editFormVisible: false,
      dictionaryTypeArr: [],
      total: 0,
      searchFormItemArr: [
        { type: 'select', label: '字典类型名称', value: 'dictType', pLabel: 'descrip', pValue: 'dictType', child: [], width: '120' },
        { type: 'input', label: '字典项编码', value: 'dictId', width: '120' },
        { type: 'input', label: '字典项描述', value: 'dictName', width: '120' }
      ],
      itemData: [
        { label: '字典类型名称', prop: 'descrip' },
        { label: '排序', prop: 'sort' },
        { label: '字典项编码', prop: 'dictId' },
        { label: '字典项描述', prop: 'dictName' },
        { label: '是否有效', prop: 'status' }
      ],
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.addAndEdit }
      ]
    }
  },
  watch: {
    dictType: {
      handler(newVal) {
        this.formInline.dictType = newVal
        this.getdata()
      },
      immediate: true
    }
  },
  created() {
    getDictionaryTypeAll(res => {
      this.searchFormItemArr[0].child = this.dictionaryTypeArr = [...res.data]
    })
  },
  methods: {
    // 获取分页数据
    getdata(type) {
      if (!type) this.formInline.pageNum = 1
      getDictionaryDataByPage(this.formInline, res => {
        this.total = res.data.total
        this.listData = res.data.pageData
      })
    },
    // 保存
    submitForm(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          this.loading = true
          // 请求方法
          saveDictionaryData(this.editForm, () => {
            this.loading = false
            this.editFormVisible = false
            this.$message.success('成功！')
            this.getdata(true)
          }, () => {
            this.loading = false
          })
        }
      })
    },
    // 点击弹出弹出层
    addAndEdit(row) {
      this.editFormVisible = true
      if (row.constructor === Object) {
        this.editForm = { ...row }
        this.title = '编辑字典项'
        return
      }
      this.editForm = {}
      this.title = '添加字典项'
      if (row === 6) {
        this.editForm.dictType = this.dictType
      }
    }
  }
}
</script>

<style lang="scss">
.dictionaryData{
.search {
  margin-top: 20px;
}
.el-select {
  width: 100%;
}
}
</style>
